import React from "react";

export default function CharactersDetails({ data }) {
  return (
    <div className="container text-center my-4">
      <h1
        className="text-light py-5 my-5"
        title="Know more about the characters!"
      >
        Characters
      </h1>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        {data.map((characters, i) => {
          return (
            <div className="col mb-4" key={i}>
              <div className="card mx-2 h-100" id={i}>
                <img
                  src="/bg-cards.svg"
                  className="card-img-top"
                  alt="Star Wars Card"
                />
                <div className="card-body">
                  <h5 className="card-title mb-3">{characters.name}</h5>
                  <p className="card-title">
                    <b>Gender:</b> {characters.gender}
                  </p>
                  <p className="card-title">
                    <b>Height:</b> {characters.height}
                  </p>
                  <p className="card-title">
                    <b>Mass:</b> {characters.mass}
                  </p>
                  <p className="card-title">
                    <b>Birth year:</b> {characters.birth_year}
                  </p>
                  <p className="card-title">
                    <b>Eye color:</b> {characters.eye_color}
                  </p>
                  <p className="card-title">
                    <b>Hair color:</b> {characters.hair_color}
                  </p>
                  <p className="card-title">
                    <b>Skin color:</b> {characters.skin_color}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
