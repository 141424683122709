import React from "react";
import { Link } from "react-router-dom";

export default function Characters({ data }) {
  return (
    <div className="container text-center my-4">
      <h1
        className="anim-up text-light py-5 my-5"
        title="Know more about the characters!"
      >
        Characters
      </h1>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        {data.map((characters, i) => {
          return (
            <div className="anim-up col mb-4" key={i}>
              <Link to="/charactersDetails">
                <div
                  id={i}
                  className="card card-anim mx-2 h-100"
                  title="Click to see more info!"
                >
                  <img
                    src="/bg-cards.svg"
                    className="card-img-top"
                    alt="Star Wars Card"
                  />
                  <div className="card-body bg-dark">
                    <h5 className="card-title text-light">{characters.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
