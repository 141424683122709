import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Characters from "./components/Characters";
import Planets from "./components/Planets";
import Starships from "./components/Starships";
import CharactersDetails from "./components/CharactersDetails";
import PlanetsDetails from "./components/PlanetsDetails";
import StarshipsDetails from "./components/StarshipsDetails";
import About from "./components/About";
import Error404 from "./components/Error404";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

export default function App() {
  const [characters, setCharacters] = useState([]);
  const [planets, setPlanets] = useState([]);
  const [starships, setStarships] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function fetchCharacters() {
      let res = await fetch("https://swapi.dev/api/people/?format=json");
      let data = await res.json();
      setCharacters(data.results);
    }

    async function fetchPlanets() {
      let res = await fetch("https://swapi.dev/api/planets/?format=json");
      let data = await res.json();
      setPlanets(data.results);
    }

    async function fetchStarships() {
      let res = await fetch("https://swapi.dev/api/starships/?format=json");
      let data = await res.json();
      setStarships(data.results);
    }

    fetchCharacters();
    fetchPlanets();
    fetchStarships().then(() => setFetching(false));
  }, []);

  return (
    <>
      <Router>
        {fetching ? (
          <div className="loader text-light text-center">
            <div className="spinner-border" role="status"></div>
            <h5 className="sr-only mt-3">Joining the dark side...</h5>
          </div>
        ) : (
          <div>
            <Navbar />
            <Routes>
              <Route path="*" element={<Error404 />} />
              <Route path="/" element={<Home />} />
              <Route
                path="/characters"
                element={<Characters data={characters} />}
              />
              <Route path="/planets" element={<Planets data={planets} />} />
              <Route
                path="/starships"
                element={<Starships data={starships} />}
              />
              <Route
                path="/charactersDetails"
                element={<CharactersDetails data={characters} />}
              />
              <Route
                path="/planetsDetails"
                element={<PlanetsDetails data={planets} />}
              />
              <Route
                path="/starshipsDetails"
                element={<StarshipsDetails data={starships} />}
              />
              <Route path="/about" element={<About />} />
            </Routes>
            <Footer />
          </div>
        )}
      </Router>
    </>
  );
}
