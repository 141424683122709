import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="bg-dark py-4 d-flex justify-content-between">
      <div className="d-inline mx-3">
        <Link to="/">
          <h4 className="text-light mt-1 mx-4" title="Let's explore together!">
            Star Wars World
          </h4>
        </Link>
      </div>
      <div className="d-inline mx-3">
        <Link to="/">
          <span className="btn text-light fw-bold mx-2" title="Home">
            Home
          </span>
        </Link>
        <Link to="/characters">
          <span className="btn text-light fw-bold mx-2" title="Characters">
            Characters
          </span>
        </Link>
        <Link to="/planets">
          <span className="btn text-light fw-bold mx-2" title="Planets">
            Planets
          </span>
        </Link>
        <Link to="/starships">
          <span className="btn text-light fw-bold mx-2" title="Starships">
            Starships
          </span>
        </Link>
        <Link to="/about">
          <span className="btn text-light fw-bold mx-2" title="About">
            About
          </span>
        </Link>
      </div>
    </nav>
  );
}
