import React from "react";

export default function StarshipsDetails({ data }) {
  return (
    <div className="container text-center my-4">
      <h1
        className="text-light py-5 my-5"
        title="Know more about the starships!"
      >
        Starships
      </h1>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        {data.map((starships, i) => {
          return (
            <div className="col mb-4" key={i}>
              <div className="card mx-2 h-100" id={i}>
                <img
                  src="/bg-cards.svg"
                  className="card-img-top"
                  alt="Star Wars Card"
                />
                <div className="card-body">
                  <h5 className="card-title mb-3">{starships.name}</h5>
                  <p className="card-title">
                    <b>MGLT:</b> {starships.MGLT}
                  </p>
                  <p className="card-title">
                    <b>Crew:</b> {starships.crew}
                  </p>
                  <p className="card-title">
                    <b>Passengers:</b> {starships.passengers}
                  </p>
                  <p className="card-title">
                    <b>Length:</b> {starships.length}
                  </p>
                  <p className="card-title">
                    <b>Consumables:</b> {starships.consumables}
                  </p>
                  <p className="card-title">
                    <b>Cargo capacity:</b> {starships.cargo_capacity}
                  </p>
                  <p className="card-title">
                    <b>Cost in credits:</b> {starships.cost_in_credits}
                  </p>
                  <p className="card-title">
                    <b>Hyperdrive rating:</b> {starships.hyperdrive_rating}
                  </p>
                  <p className="card-title">
                    <b>Model:</b> {starships.model}
                  </p>
                  <p className="card-title">
                    <b>Manufacturer:</b> {starships.manufacturer}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
