import React from "react";

export default function Footer() {
  return (
    <footer className="bg-dark text-center py-4">
      <span className="text-light align-baseline" title="MCTW | 2022/23">
        Tiago Álvaro | 100124
      </span>
    </footer>
  );
}
