import React from "react";

export default function Error404() {
  return (
    <div className="text-center">
      <div className="anim-up py-4 my-4">
        <h1 className="text-light error-404">404</h1>
        <h3 className="text-light my-3">You lost your own way my son</h3>
      </div>
      <img
        className="anim-up vw-50"
        src="/bg-darthvader.png"
        alt="Darth Vader Background"
        title="You underestimated the power of the dark side..."
      />
    </div>
  );
}
