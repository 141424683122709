import React from "react";

export default function PlanetsDetails({ data }) {
  return (
    <div className="container text-center my-4">
      <h1 className="text-light py-5 my-5" title="Know more about the planets!">
        Planets
      </h1>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        {data.map((planets, i) => {
          return (
            <div className="col mb-4" key={i}>
              <div className="card mx-2 h-100" id={i}>
                <img
                  src="/bg-cards.svg"
                  className="card-img-top"
                  alt="Star Wars Card"
                />
                <div className="card-body">
                  <h5 className="card-title mb-3">{planets.name}</h5>
                  <p className="card-title">
                    <b>Diameter:</b> {planets.diameter}
                  </p>
                  <p className="card-title">
                    <b>Gravity:</b> {planets.gravity}
                  </p>
                  <p className="card-title">
                    <b>Orbital period:</b> {planets.orbital_period}
                  </p>

                  <p className="card-title">
                    <b>Rotation period:</b> {planets.rotation_period}
                  </p>
                  <p className="card-title">
                    <b>Surface water:</b> {planets.surface_water}
                  </p>
                  <p className="card-title">
                    <b>Population:</b> {planets.population}
                  </p>
                  <p className="card-title">
                    <b>Climate:</b> {planets.climate}
                  </p>
                  <p className="card-title">
                    <b>Terrain:</b> {planets.terrain}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
