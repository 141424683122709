import React from "react";

export default function Home() {
  return (
    <div className="text-center">
      <h1
        className="anim-up text-light pt-5 mt-5 mb-0"
        title="Nice to have you here!"
      >
        Welcome to Star Wars World!
      </h1>
      <img
        className="anim-up w-100"
        src="/bg-starwars.jpg"
        alt="Star Wars Background"
      />
    </div>
  );
}
