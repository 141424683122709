import React from "react";

export default function About() {
  return (
    <div className="text-center">
      <h1
        className="anim-up text-light py-5 my-5"
        title="Know more about the project!"
      >
        About
      </h1>
      <div className="anim-up">
        <h5 className="text-light">
          This React application was developed using{" "}
          <a
            className="text-light"
            href="https://swapi.dev/"
            target="_blank"
            rel="noreferrer"
            title="I want to know more!"
          >
            The Star Wars API
          </a>
          .
        </h5>
        <h5 className="text-light">
          It´s an open source library that provides data about the Star Wars
          universe.
        </h5>
        <h5 className="text-light">
          The API allows access to information about characters, planets,
          spaceships, and more.
        </h5>
      </div>
      <div className="anim-up py-5 my-5">
        <h5 className="text-light mt-2">
          Projeto desenvolvido no âmbito da UC de Tecnologias e Desenvolvimento
          Web
        </h5>
      </div>
    </div>
  );
}
